import React from "react";
import {
  // HashRouter as Router,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from "../pages/Home";
import HomeFR from "../pages/HomeFR";
import LoginPage from "../pages/LoginPage";
import LoginPageFR from "../pages/LoginPageFR";

// import Header from "../components/Header";
 
const index = () => {
  return (
    <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/en" element={<Home/>} />
        <Route path="/fr" element={<HomeFR/>} />
        <Route path="/" element={<HomeFR/>} />
        <Route path="/en/login" element={<LoginPage/>} />
        <Route path="/fr/connexion" element={<LoginPageFR/>} />
        <Route path='/redirect-page' element={ <Navigate to="/error-page" /> }/>
        <Route path="*" element={<Navigate to="/fr/connexion" />} />
      </Routes>
    </Router>
    </React.StrictMode>
  );
};
 
export default index;