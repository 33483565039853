import { useEffect, useState } from "react";
import { UidContext } from "./components/AppContext";
import Routes from "./routes";
 
function App() {
 
  const [uid, setUid] = useState(null);
 
  useEffect(() => {
    setUid(sessionStorage.getItem("authToken"));
  }, uid);
 
 
  return (
    <UidContext.Provider value={uid}>
      <Routes />
    </UidContext.Provider>
    // <Routes />
  );
}
 
export default App;