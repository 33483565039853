import React, { useContext } from "react";
import Log from "../components/Login";
import { Navigate } from "react-router-dom";
import { UidContext } from "../components/AppContext";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';


// AFFICHAGE DE LA PAGE HOME AVEC DIFFERENTE CONDITION (SI L'UTILISATEUR EST CONNECTE OU NON)
const LoginPage = () => {
  const uid = useContext(UidContext);

  return (
    <>
    <Helmet>
      <title>Track My Order - Home Société Group</title>
      <meta name="description" content="Home Société group web and store order tracking site" />
    </Helmet>
    {/* <header>
        <ul>
          <li>
            <NavLink
              exact
              to="/fr/connexion"
              title="FR"
              // activeClassName="header-activ"
              // onClick={() => {
              //   setShowLinks(false);
              // }}
            >
              <h3>FR</h3>
            </NavLink>
          </li>
          <li>
          <Logout/>
          </li>
        </ul>
    </header> */}
    <div className="login-page">
      {uid ? (
        <Navigate to="/en" />
      ) : (
        <div className="log-container">
          <Log />
        </div>
      )}
    </div>
    </>
  );
};

export default LoginPage;
