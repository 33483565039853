import React, { useState, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
// require("dotenv").config();
 
const LoginFR = () => {
  const [email, setEmail] = useState("");
  const [orderid, setOrderid] = useState("");
  const recaptchaRef = useRef(); // Référence pour le reCAPTCHA

 
  // FONCTION QUI PERMET LA CONNEXION D'UN UTILISATEUR GRACE A UN APPEL API AXIOS AU BACKEND: LOGIN
  const handleLogin = async (e) => {
    e.preventDefault();
    const emailError = document.querySelector(".email.error");
    const orderidError = document.querySelector(".orderid.error");
    const emailInput = document.querySelector("#email");
    const orderidInput = document.querySelector("#orderid");

    emailError.innerHTML = "";
    orderidError.innerHTML = "";

    try {
      // Exécuter le reCAPTCHA et récupérer le token
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset(); // Réinitialiser le reCAPTCHA après exécution

      const data = {
        email: emailInput.value,
        orderid: orderidInput.value,
        recaptchaToken: recaptchaToken // Inclure le token reCAPTCHA
      };

      // Envoi de la requête au serveur avec le token reCAPTCHA
      // const res = await axios.post("http://localhost:3001/api/login", data);
      const res = await axios.post("/api/login", data);

      if (res.data.error1) {
        emailError.innerHTML = res.data.error1;
        orderidError.innerHTML = "";
      } else if (res.data.error2) {
        emailError.innerHTML = "";
        orderidError.innerHTML = res.data.error2;
      } else {
        sessionStorage.setItem("authToken", res.data.token);
        setTimeout(() => {
          window.location = "/fr";
        }, 2000);
      }
    } catch (error) {
      // Gestion des erreurs
      if (error.response) {
        console.error('Server Error:', error.response.data);
        if (error.response.data.error1) {
          // emailError.innerHTML = error.response.data.error1;
          emailError.innerHTML = 'Courriel invalide'; // Erreur liée à l'email
          emailInput.classList.add("borderError");
        }
        if (error.response.data.error2) {
          // orderidError.innerHTML = error.response.data.error2;
          orderidError.innerHTML = "Numéro de commande invalide"; // Erreur liée à l'Order ID
          orderidInput.classList.add("borderError");
          emailInput.classList.remove("borderError");
        }
      } else if (error.request) {
        console.error('No response:', error.request);
        emailError.innerHTML = "Aucune réponse du serveur.";
      } else {
        console.error('Error:', error.message);
        emailError.innerHTML = "Erreur : " + error.message;
      }
    }

    emailInput.classList.remove("borderError");
    orderidInput.classList.remove("borderError");
  };

 
  return (
    
    <form action="" onSubmit={handleLogin} id>
      <label htmlFor="email">Courriel</label>
      
      <input
        type="text"
        name="email"
        id="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        required="required"
        autoFocus
        placeholder="email@example.com"

      />
      <div className="email error"></div>
      <br />
      <label htmlFor="orderid">Numéro de Commande</label>
      
      <input
        type="text"
        name="orderid"
        id="orderid"
        onChange={(e) => setOrderid(e.target.value)}
        value={orderid}
        required="required"
        autoComplete="true"
        placeholder="X12345 ou 123456"
      />
      <div className="orderid error"></div>
      <br />
      <input type="submit" value="Se connecter" className="validLS" />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LfMxk8qAAAAAFjoQHrevd88qOjdi1vLLmdtvnmZ"  // Clé publique
        size="invisible"
      />
    </form>
  );
};
 
export default LoginFR;