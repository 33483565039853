import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';




const ProductDetail = ({Commande}) => {
  const navigate = useNavigate();

  const [ProductDetailList, setProductDetailList] = useState([]);
  const [loading, setLoading] = useState(true); // Ajout de l'état de chargement

  useEffect(() => {
    const getCommande = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
 
        if (!token) {
          sessionStorage.removeItem("authToken");
          navigate("/en/login"); 
          return;
        }
        
        // const response = await axios.get('http://localhost:3001/api/product', {
        const response = await axios.get('/api/product', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
 
 
        if (response.data) {
          setProductDetailList(response.data); 
        }
      } catch (error) {
        console.error('Failed to fetch command:', error);
      } finally {
        setLoading(false); // Terminer le chargement après avoir reçu les données
      }
    };
    getCommande();
  },[]); 

  if (loading) {
    return <p>Chargement des données...</p>;
  }


  // Converti la date présente
  const currentDate = new Date();
  const formattedDate = currentDate.getFullYear() + '-' 
                      + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' 
                      + String(currentDate.getDate()).padStart(2, '0');



  return (
    <>
      <ul className="orderProductWall desktop">
      {/* test */}
      {/* <pre>{JSON.stringify(ProductDetailList, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(Commande, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(ProductDetailList[0]["URL_en"], null, 2)}</pre>  */}
      {ProductDetailList.length > 1 ? (
        ProductDetailList.map((product) => {
          if (product["CodeCategorie"] === "9LI") {
            return null; // Ne rien retourner si la condition est remplie
          }
          return (
            <li
            className="product-container"
            key={product.SKU}
            > 
              <div className="productSku">
                <p>{product.SKU}</p> 
              </div>
              {/* <a href={product["URL_en"]} target="blank" className="product"></a> */}
              <div className="product">
                <figure>
                  <img
                    src={product["BASE_IMAGE"]}
                    alt={"Product" + `${product["Name_en"]}`}
                  />
                </figure>
                <div className="productInfo">
                  <h3>
                    {/* {product["Name_en"]} */}
                  {product["Name_en"] === null || product["Name_en"] === ""
                      ? product["Nom_Meublex"]
                      : product["Name_en"]}
                  </h3>
                  <p>
                  {product["FINI_DESC1"] === "" || product["FINI_DESC1"] === null
                    ? product["FINI_CODE"]
                    : product["FINI_DESC1"]}  
                  </p>    
                </div>
              </div>
              <div className="productEta">
              {product["Date_Requise_En"] === "Delivered" ? (<p style={{color:"c80000"}}>{product["Date_Requise_En"]}</p>) 
              : product["Date_Requise_En"] < formattedDate && product["Statut Commande"] === "En attente de livraison" ? ( <p style={{color:"#c01f1f"}}>Awaiting scheduling</p> ) 
              : <p>{product["Date_Requise_En"]}</p> }
              </div>
              <div className="productQty">
                <p>{product["sum_QteVendue"]}</p> 
              </div>
              <div className="productPrice">
                <p> {"$" + `${product["PrixUnitaire"].toFixed(2)}`}</p> 
              </div>
            </li>
                  );
                })
        ) : 
                <li
                className="product-container"
                key={ProductDetailList[0].SKU}
                > 
                  <div className="productSku">
                    <p>{ProductDetailList[0].SKU}</p> 
                  </div>
                  {/* <a href={ProductDetailList[0]["URL_en"]} target="blank" className="product"></a> */}
                  <div className="product">
                    <figure>
                      <img
                        src={ProductDetailList[0]["BASE_IMAGE"]}
                        alt={"Product" + `${ProductDetailList[0]["Name_en"]}`}
                      />
                    </figure>
                    <div className="productInfo">
                      <h3>
                        {/* {ProductDetailList[0]["Name_en"]} */}
                        {ProductDetailList[0]["Name_en"] === null || ProductDetailList[0]["Name_en"] === ""
                          ? ProductDetailList[0]["Nom_Meublex"]
                          : ProductDetailList[0]["Name_en"]}
                        </h3>
                      <p>
                      {ProductDetailList[0]["FINI_DESC1"] === "" || ProductDetailList[0]["FINI_DESC1"] === null
                        ? ProductDetailList[0]["FINI_CODE"]
                        : ProductDetailList[0]["FINI_DESC1"]}
                      </p>    
                    </div>
                  </div>
                  <div className="productEta">
                    {ProductDetailList[0]["Date_Requise_En"] === "Delivered" ? ( <p style={{color:"c80000"}}>{ProductDetailList[0]["Date_Requise_En"]}</p> ) 
                    : ProductDetailList[0]["Date_Requise_En"] < formattedDate && ProductDetailList[0]["Statut Commande"] === "En attente de livraison" ? ( <p style={{color:"#c01f1f"}}>Awaiting scheduling</p> ) 
                    : <p>{ProductDetailList[0]["Date_Requise_En"]}</p>}
                  </div>
                  <div className="productQty">
                    <p>{ProductDetailList[0]["sum_QteVendue"]}</p> 
                  </div>
                  <div className="productPrice">
                    <p> {"$" + `${ProductDetailList[0]["PrixUnitaire"].toFixed(2)}`}</p> 
                  </div>
                </li>}
  </ul>
  
  {/* VERSION MOBILE */}
  <ul className="orderProductWall mobile">
      {/* Mobile */}
      {ProductDetailList.length > 1 ? (
        ProductDetailList.map((product) => {
          if (product["CodeCategorie"] === "9LI") {
            return null; // Ne rien retourner si la condition est remplie
          }
          return (
            <li
            className="product-container"
            key={product.SKU}>
              {/* <a href={product["URL_en"]} target="blank" className="product"></a> */}
              <div className="product">
                <figure>
                  <img
                    src={product["BASE_IMAGE"]}
                    alt={"Product" + `${product["Name_en"]}`}
                  />
                </figure>
                <div className="productInfo">
                  <h3 className="margin-b-2">
                    {product["Name_en"] === null || product["Name_en"] === ""
                        ? product["Nom_Meublex"]
                        : product["Name_en"]}
                  </h3>
                  <p className="margin-b-2">
                    {product["FINI_DESC1"] === "" || product["FINI_DESC1"] === null
                      ? product["FINI_CODE"]
                      : product["FINI_DESC1"]}  
                  </p>  
                  <p>{"SKU: " + `${product.SKU}`}</p> 
                  <div className="qtyPrice margin-b-2">
                    <p className="qty">{`${product["sum_QteVendue"]}` + "x "}</p> 
                    <p> {"$" + `${product["PrixUnitaire"].toFixed(2)}`}</p> 
                  </div>  
                </div>
              </div>
              <div className="productEta">
              {product["Date_Requise_En"] === "Delivered" ? (<p style={{color:"c80000"}}>{product["Date_Requise_En"]}</p>) 
              : product["Date_Requise_En"] < formattedDate && product["Statut Commande"] === "En attente de livraison" ? ( <p style={{color:"#c01f1f"}}>Awaiting scheduling</p> ) 
              : <p>{product["Date_Requise_En"]}</p> }
              </div>
              
            </li>
                  );
                })
        ) : 
                <li
                className="product-container"
                key={ProductDetailList[0].SKU}> 
                  {/* <a href={ProductDetailList[0]["URL_en"]} target="blank" className="product"></a> */}
                  <div className="product">
                    <figure>
                      <img
                        src={ProductDetailList[0]["BASE_IMAGE"]}
                        alt={"Product" + `${ProductDetailList[0]["Name_en"]}`}
                      />
                    </figure>
                    <div className="productInfo">
                      <h3>
                        {ProductDetailList[0]["Name_en"] === null || ProductDetailList[0]["Name_en"] === ""
                          ? ProductDetailList[0]["Nom_Meublex"]
                          : ProductDetailList[0]["Name_en"]}
                      </h3>
                      <p>
                        {ProductDetailList[0]["FINI_DESC1"] === "" || ProductDetailList[0]["FINI_DESC1"] === null
                          ? ProductDetailList[0]["FINI_CODE"]
                          : ProductDetailList[0]["FINI_DESC1"]}
                      </p> 
                      <p>{"SKU: " + `${ProductDetailList[0]["SKU"]}`}</p> 
                      <div className="qtyPrice margin-b-2">
                        <p className="qty">{`${ProductDetailList[0]["sum_QteVendue"]}` + "x "}</p> 
                        <p> {"$" + `${ProductDetailList[0]["PrixUnitaire"].toFixed(2)}`}</p> 
                      </div>  
                    </div>
                  </div>
                  <div className="productEta">
                  {ProductDetailList[0]["Date_Requise_En"] === "Delivered" ? ( <p style={{color:"c80000"}}>{ProductDetailList[0]["Date_Requise_En"]}</p> ) 
                    : ProductDetailList[0]["Date_Requise_En"] < formattedDate && ProductDetailList[0]["Statut Commande"] === "En attente de livraison" ? ( <p style={{color:"#c01f1f"}}>Awaiting scheduling</p> ) 
                    : <p>{ProductDetailList[0]["Date_Requise_En"]}</p>}
                  </div>
                </li>}
            </ul>
    </>
    

    
  );
};

export default ProductDetail;