import React, { useContext } from "react";
import LogFR from "../components/LoginFR";
import { Navigate } from "react-router-dom";
import { UidContext } from "../components/AppContext";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';


// AFFICHAGE DE LA PAGE HOME AVEC DIFFERENTE CONDITION (SI L'UTILISATEUR EST CONNECTE OU NON)
const LoginPageFR = () => {
  const uid = useContext(UidContext);

  return (
    <>
    <Helmet>
      <title>Suivi de commandes - Groupe Home Société</title>
      <meta name="description" content="Site de suivi de commandes web et magasin du groupe Home Société" />
    </Helmet>
    {/* <header>
      <ul>
          <li>
            <NavLink
              exact
              to="/en/login"
              title="EN"
              // activeClassName="header-activ"
              // onClick={() => {
              //   setShowLinks(false);
              // }}
            >
              <h3>EN</h3>
            </NavLink>
          </li>
          <li>
          <LogoutFR/>
          </li>
        </ul>
    </header> */}
    <div className="login-page">
      {uid ? (
        <Navigate to="/fr" />
      ) : (
        <div className="log-container">
          <LogFR />
        </div>
      )}
    </div>
    </>
  );
};

export default LoginPageFR;
